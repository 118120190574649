export const environment = {
    production: false,
    //apiUrl: 'https://broken-night-7120.getsandbox.com',
    apiUrl:'https://qa-test-rolling-ob-twin.enacloud.me',
  
    apiUrlBuffer: '',
  
    client: '',
    redirectUri: 'http://localhost:4200',
    authority: '',
  };
  